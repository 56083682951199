import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HeadnavbarComponent} from './headnavbar/headnavbar.component';
import {childTokenGuard} from './Guards/child-token.guard';
import { EventlistComponent } from './eventlist/eventlist.component';
import { AddEventComponent } from './add-event/add-event.component';
/* import { AddMailFolderComponent } from './add-mail-folder/add-mail-folder.component'; */
import { SetupComponent } from './question-master-lazy/setup/setup.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { EventallComponent } from './eventall/eventall.component';
import { RefreshComponent } from './refresh/refresh.component';
import { RecoverComponent } from './recover/recover.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
	{path: '', redirectTo: '/login', pathMatch: 'full'},
	{path: 'refresh', component: RefreshComponent},
	{path: 'login', component: LoginComponent},
	{path: 'recover', component: RecoverComponent},
	{path: 'setnewpassword/:email/:token', component: ResetPasswordComponent},
	{
		path: 'app', component: HeadnavbarComponent, data: {breadcrumb: 'TopNavBar'}, canActivate:[childTokenGuard], children: [
			{path: 'eventList', component: EventlistComponent},
			{path: 'eventList/:search', component: EventlistComponent},
			{path: 'generateEvent', component: AddEventComponent},
			{path: 'generateVesselEvent/:transactionId/:candidateId/:vesselId', component: AddEventComponent},
			/* {path: 'addMailFolder', component: AddMailFolderComponent},
			{path: 'editMailFolder/:folderId', component: AddMailFolderComponent}, */
			{path: 'questionSetup', component: SetupComponent},
			{path: 'createEvent/:transactionId/:vesselId', component: CreateEventComponent},
			{path: 'allEvents', component: EventallComponent},
			{path: 'allEvents/:vesselId', component: EventallComponent},
			{path: 'allEvents/:vesselId/:transactionId', component: EventallComponent},
			{path: 'eventDetails/:eventId',loadChildren: () => import('./event-details/event-details.module').then(m => m.EventDetailsModule)},
			{path: 'videoCall', loadChildren: () => import('./video-call-lazy/video-call-lazy.module').then(m => m.VideoCallLazyModule)},
			{path: 'mailbox',data: {breadcrumb: 'Folder List'},loadChildren: () => import('./lazy-mailbox/lazy-mailbox.module').then(m => m.LazyMailboxModule)},
			{path: 'admin', loadChildren: () => import('./admin-lazy/admin-lazy.module').then(m => m.AdminLazyModule)},
			{path: 'assignDoctor',loadChildren: () => import('./assign-doctor-lazy/assign-doctor-lazy.module').then(m => m.AssignDoctorLazyModule)},
			{path: 'addQuestions/:id',loadChildren: () => import('./question-master-lazy/question-master-lazy.module').then(m => m.QuestionMasterLazyModule)},
		]
	},
	{path: 'joinCall', loadChildren: () => import('./video-call-lazy/video-call-lazy.module').then(m => m.VideoCallLazyModule)},
	{path: '**', redirectTo: '/login'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
