<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
<section class="content">
	<div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'" class="form-group row">
		<div [routerLink]="['/app/admin/followup']" id="linkFollowup" class="text-center col-lg-3 col-sm-3 col-xs-6" style="cursor: pointer;">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-NewMail">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<i class="fa fa-calendar-plus-o"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						Scheduled Calls
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{followupcnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
		<div [routerLink]="['/app/mailbox/folder/unlinked']" id="linkUnlinked" class="text-center col-lg-3 col-sm-3 col-xs-6" style="cursor: pointer;">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-unlink">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<i class="fa fa-chain-broken"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						Un-Linked
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{unlinkedCnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
		<div id="linkListVessel" class="text-center col-lg-3 col-sm-3 col-xs-6">
			<div ripple="" style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-vessl">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<i class="fa fa-folder"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						Open Cases
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{openEventcnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
		<div title="All Case" class="text-center col-lg-3 col-sm-3 col-xs-6" style="cursor: pointer;" [routerLink]="['/app/allEvents']" id="linkAllEvents">
			<div style="border-radius: 4%;" class="panel panel-default">
				<div style="border-radius: 4%;" class="panel-body bg-shps">
					<h1 class="text-thin text-white" style="margin-bottom: 0px;"><i class="fa fa-folder"></i>
					</h1>
					<h2 class="text-white" style="margin-bottom: 0px;">
						All Cases
					</h2>
					<h1 class="text-thin text-white" style="margin-bottom: 0px;">
						<strong>{{alleventcnt || 0}}</strong>
					</h1>
				</div>
				<span class="ripple"></span>
			</div>
		</div>
	</div>
	<div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'" class="form-group row">
		<div class="col-sm-12">
			<div class="panel panel-default">
				<div style="background-color: #586acc !important;" class="panel-heading">
					<h2 class="text-thin text-white text-center mt0 mb0">
						<strong style="font-size: 23px;">Open Cases</strong>
					</h2>
				</div>
				<div>
					<div style="padding-top: 10px;width: 100%;" class="pull-left">
						<input autofocus id="searchBox" placeholder="Search By Case ID, Vessel, Candidate, Fleet" style="font-size: 16px;margin-left: 5px;width: 20%;float: left;" [formControl]="searchControl" class="form-control" />
						<ng-select style="margin-left: 5px;width: 30%;float: left;" [items]="tagMaster" appendTo="body" [clearable]="true" [multiple]="true" placeholder="Search By Tags" id="tags" name="tags" [(ngModel)]="tableparams.tags" (change)="searchByTags()"></ng-select>
						<ng-select style="margin-left: 5px;width: 15%;float: left;" [items]="flagList" bindLabel="name" bindValue="id" appendTo="body" [clearable]="true" [multiple]="false" placeholder="Search By TRIAGE" [(ngModel)]="tableparams.flag" (change)="flagOpenEvents()">
							<ng-template ng-label-tmp let-item="item">
								<i [ngClass]="item.class"></i>{{item.name}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
								<i [ngClass]="item.class"></i>{{item.name}}
							</ng-template>
						</ng-select>
						<div class="checkbox c-checkbox pull-left ml-sm" style="width:170px !important;">
							<label>
								<input type="checkbox" id="checkisReplied" [(ngModel)]="tableparams.mark_for_closure" [ngModelOptions]="{standalone: true}" (change)="markForClosureChange()" />
								<span class="fa fa-check"></span>
								Marked for closure
							</label>
						</div>
						<div class="checkbox c-checkbox pull-left" style="width:110px !important;margin-top: 10px;">
							<label>
								<input type="checkbox" id="checkisReplied" [(ngModel)]="tableparams.isReplied" [ngModelOptions]="{standalone: true}" (change)="toggleNotReplied()" />
								<span class="fa fa-check"></span>
								Not Replied
							</label>
						</div>
						<button *ngIf="userTypeName =='Basic Admin'" type="button" class="btn btn-sm btn-info ml mr pull-right" (click)="openModal()">Analytics</button>
					</div>
					<div class="table-responsive" style="max-height: 450px;overflow-y: auto;width: 100%;">
						<table class="table table-striped table-bordered table-hover">
							<thead>
								<tr>
									<th class="tableth_heading text-center">
										Case ID
										<i (click)="sortTableBy('id')" id="sortTableByBtnId" class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">
										Date Of Case
										<i (click)="sortTableBy('createdAt')" id="sortTableByBtncreatedAt" class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">
										Vessel Name
										<i (click)="sortTableBy('vesselName')" id="sortTableByBtnvesselName" class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">
										Candidate Name
										<i (click)="sortTableBy('candname')" id="sortTableByBtncandname" class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">
										Symptoms
									</th>
									<th class="tableth_heading text-center">
										Doctor Name
										<i (click)="sortTableBy('doctorName')" id="sortTableByBtndoctorName" class="fa fa-sort ml" style="cursor: pointer;"></i>
									</th>
									<th class="tableth_heading text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let eve of eventlist; let i = index" class="table_td_style">
									<td class="text-center">
										{{eve.eventId}}
										<span *ngIf="eve.emailStatus=='Unread'" title="New mail" class="mr-sm">
											<i aria-hidden="true" class="fa fa-envelope"></i>
										</span>
										<span *ngIf="eve.isReplied==1" title="Not Replied">
											<i aria-hidden="true" style="color: #3f51b5;" class="fa fa-envelope"></i>
										</span>
									</td>
									<td class="text-center">{{fixDate(eve.createdAt)}}</td>
									<td class="text-uppercase">
										<i class="fa fa-info-circle mr" [title]="'Fleet : ' + eve.com_name"></i>
										{{eve.vesselName}}
									</td>
									<td class="text-center text-uppercase">{{eve.candname}}</td>
									<td title="{{eve.symptomsText}}">{{eve.symptomsText | limitTo:'20'}}</td>
									<td class="text-center text-uppercase">{{eve.doctorName || '-'}}</td>
									<td class="text-center">
										<button title="View Case Details" [ngStyle]="set_color(eve)" [routerLink]="['/app/eventDetails',eve.id]" class="btn btn-xs text-white text-bold" id="btnViewDetails{{i}}">View</button>
									</td>
								</tr>
							</tbody>
						</table>
						<div *ngIf="!eventlist.length &amp;&amp; !isLoading" class="text-center text-bold">
							Data Not Found.
						</div>
						<div *ngIf="isLoading" class="text-center text-bold">
							<i aria-hidden="true" class="fa fa-spinner fa-spin"></i> Loading more events.
						</div>
						<app-infinite-scroll (scrolled)="getEventList()" *ngIf="!isLoading">
						</app-infinite-scroll>
						<div *ngIf="!isLoading && !isListFinished" class="text-center text-bold">
							<button id="btnLoadMore" title="Load More" (click)="getEventList()" class="btn btn-primary">Load More</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="moduleAccess!='Full' && moduleAccess!='Readonly'">
		<div class="text-center">
			<h4>You don&apos;t have access to this section.</h4>
		</div>
	</div>
</section>
<div class="modal fade" id="dataModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" (click)="clearDataModal();">&times;</button>
				<h4 class="modal-title"><b>Select Period</b></h4>
			</div>
			<div class="modal-body">
				<div class="row form-group">
					<div class="col-md-6">
						<label class="control-label">
							From Date:
							<!-- <button type="button" class="btn btn-xs btn-primary" (click)="analyticsPayload.fromDate=null;">Clear</button> -->
						</label>
						<div class="mat-datepicker-container">
							<input id="analyticsPayload_fromDate" [matDatetimepicker]="analytics_from_date_time_picker" [(ngModel)]="analyticsPayload.fromDate" (click)="analytics_from_date_time_picker.open()" placeholder="From" readonly class="form-control" matInput>
							<mat-datetimepicker-toggle matSuffix [for]="analytics_from_date_time_picker"></mat-datetimepicker-toggle>
						</div>
						<mat-datetimepicker type="datetime" [timeInterval]="5" #analytics_from_date_time_picker></mat-datetimepicker>
					</div>
					<div class="col-md-6">
						<label class="control-label">
							To Date:
							<!-- <button type="button" class="btn btn-xs btn-primary" (click)="analyticsPayload.toDate=null;">Clear</button> -->
						</label>
						<div class="mat-datepicker-container">
							<input id="analyticsPayload_toDate" [matDatetimepicker]="analytics_to_date_time_picker" [(ngModel)]="analyticsPayload.toDate" (click)="analytics_to_date_time_picker.open()" placeholder="To" readonly class="form-control" matInput>
							<mat-datetimepicker-toggle matSuffix [for]="analytics_to_date_time_picker"></mat-datetimepicker-toggle>
						</div>
						<mat-datetimepicker type="datetime" [timeInterval]="5" #analytics_to_date_time_picker></mat-datetimepicker>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-12">
						<label class="control-label">Case Status:</label>
						<ng-select [items]="statusList" appendTo="body" bindLabel="name" bindValue="id" [clearable]="true" [multiple]="true" placeholder="Select" id="case_status" name="case_status" [(ngModel)]="analyticsPayload.case_status">
						</ng-select>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-12">
						<label class="control-label">Assigned To:</label>
						<ng-select appendTo="body" id="doctorSearch" [items]="doctorList" bindLabel="doct_name" bindValue="id"
							[clearable]="true" [multiple]="true" placeholder="Select Doctor"
							[searchFn]="doctorCustomSearch" (search)="searchFunctionDoctor($event)"
							[(ngModel)]="analyticsPayload.doctor_id"
							[ngModelOptions]="{standalone: true}"></ng-select>
					</div>
				</div>
			</div>
			<div class="modal-footer text-center">
				<p *ngIf="analyticsError" style="color: maroon;">{{analyticsError}}</p>
				<button id="btnSubmit1" type="button" class="btn btn-success" (click)="getEventData()">
					<i *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin mr-sm"></i>
					Submit
				</button>
				<button id="btnDelete" type="button" class="btn btn-primary" data-dismiss="modal" (click)="clearDataModal()">Close</button>
			</div>
		</div>
	</div>
</div>