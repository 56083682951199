import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../api.service';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Output} from '@angular/core';
import { FileQueueObject, FileuploadService } from '../fileupload.service';
import * as _ from 'lodash';
declare var $: any;
import { debounceTime, map, distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-eventall',
  templateUrl: './eventall.component.html',
  styleUrl: './eventall.component.css'
})
export class EventallComponent implements OnInit {
	breadcrumbList:any[] = [];
	moduleAccess;
	replymoduleAccess;
	spammoduleAccess;
	mailFoldermoduleAccess
	eventlist:any = [];
	isListFinished :boolean = false;
	isLoading :boolean = false;
	
	tableparams = {
		page:0,
		limit:20,
		category:"",
		flag:null,
		isReplied:0,
		case:"Open",
		tags:[],
		mark_for_closure:false
	};
	
	formCtrlSub!: Subscription;
	searchControl = new FormControl();
	excelDownloadLoader = false;
	excelDownloadParams:any={category:'',flag:'',isReplied:0,case:"",tags:[],mark_for_closure:false};
	tagMaster:any = [];
	vesselId:any = null;
	transactionId:any = null;
	vesselData : any = null;
	
	constructor(private toastr: ToastrService,private apiService: ApiService,private router: Router,private activeRoute: ActivatedRoute,private location:Location,public uploader: FileuploadService) {}
	
	ngOnInit(): void {
		this.activeRoute.queryParams.subscribe(queryParams => {});
		this.activeRoute.params.subscribe(routeParams => {
			this.vesselId = routeParams["vesselId"] == undefined ? null : routeParams["vesselId"];
			if(this.vesselId){
				this.transactionId = routeParams["transactionId"] == undefined ? null : routeParams["transactionId"];
				let url = "/app/admin/listVessels/"+this.vesselId+"/empty";
				if(this.transactionId){
					url += "/"+this.transactionId+"/empty";
				}else{
					url += "/empty/empty";
				}
				this.breadcrumbList = [
					{
						path:"/app/eventList",
						name:"Home"
					},
					{
						path:url,
						name:"Vessel List"
					},
					{
						path:"/app/allEvents/"+this.vesselId,
						name:"Vessel Events"
					}
					];
					this.vesselDetails();
			}else{
				this.breadcrumbList = [
					{
						path:"/app/eventList",
						name:"Home"
					},
					{
						path:"/app/allEvents",
						name:"All Cases"
					}
				];
			}
			this.moduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Events;
			this.replymoduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Reply_mail;
			this.spammoduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Mark_spam;
			this.mailFoldermoduleAccess = JSON.parse(localStorage.getItem("userAccess")!).Mail_folder;
			this.getEventList(false);
			this.fetchTags();
		});
	}
	
	ngAfterViewInit() {
		this.formCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(1000)).subscribe(newValue => {
			this.excelDownloadParams={category:'',flag:'',isReplied:this.tableparams.isReplied,case:this.tableparams.case,tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure};
			this.tableparams.page = 0;
			this.isListFinished = false;
			this.eventlist = [];
			this.getEventList(true);
		});
	}

	vesselDetails(){
		this.apiService.getVesselInfo({vesselId:this.vesselId}).subscribe(
		  data => {
			this.vesselData = data;
		  },
		  (error)=>{
			this.toastr.error("Unable To Get Vessel Data", 'Error',{
			  timeOut:3000,
			  tapToDismiss: true,
			  closeButton: true
			});
		  }
		  );
	  }

	fetchTags(){
		this.apiService.getTagSuggestions().subscribe((data:any) => {
			this.tagMaster = data;
			/* for (var i = 0; i < data.length; ++i) {
				this.tagMaster.push({display:data[i],value:data[i]});
			} */
		},(error)=>{
		});
	}
	
	fixDate(dateString){
		var date =  new Date(dateString.replace(/-/g, "/"));
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		var day = date.getDate();
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();
		var hour = date.getHours();
		var minutes = date.getMinutes()+"";
		var seconds = date.getSeconds();
		var ampm = hour >= 12 ? 'pm' : 'am';
		hour = hour % 12;
		hour = hour ? hour : 12;
		minutes = parseInt(minutes) < 10 ? '0'+minutes : minutes;
		var strTime = hour + ':' + minutes + ' ' + ampm;
		
		day = this.checkZero(day);
		month = this.checkZero(month);
		hour = this.checkZero(hour);
		minutes = this.checkZero(minutes);
		seconds = this.checkZero(seconds);
		
		let dateStr = day + ", " + month + " " + year + " " + strTime;
		return dateStr;
	}
	
	set_color(eve){
		if (eve.flag  === 4) {return { backgroundColor: '#f34235', 'border-color':'#f34235', 'font-weight' : 'bold' };}
		else if (eve.flag  === 2) {return { backgroundColor: '#fe9700' , 'border-color':'#fe9700' , 'font-weight' : 'bold'};}
		else if (eve.flag  === 3) {return { backgroundColor: '#4caf50' , 'border-color':'#4caf50', 'font-weight' : 'bold'};}
		else if (eve.flag  === 1) {return { backgroundColor: '#363f45' , 'border-color':'#363f45', 'font-weight' : 'bold'};}
		else if (eve.flag  === 5) {return { backgroundColor: '#3f51b5' , 'border-color':'#3f51b5', 'font-weight' : 'bold'};}
		else {return { backgroundColor: '#363f45' , 'border-color':'#363f45', 'font-weight' : 'bold'};}
	}
	
	flagAllEvents(flag){
		this.excelDownloadParams={category:'',flag:flag,isReplied:this.tableparams.isReplied,case:this.tableparams.case,tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure};
		this.tableparams.flag = flag;
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList(true);
	}
	
	toggleNotReplied(){
		
		this.tableparams.isReplied = this.tableparams.isReplied==0?1:0;
		this.excelDownloadParams={category:'',flag:this.tableparams.flag,isReplied:this.tableparams.isReplied,case:this.tableparams.case,tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure};
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList(true);
	}

	searchByStatus(){
		this.excelDownloadParams={category:'',flag:null,isReplied:this.tableparams.isReplied,case:this.tableparams.case,tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure};
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList(true);
	}

	searchByTags(){
		this.excelDownloadParams={category:'',flag:null,isReplied:this.tableparams.isReplied,case:this.tableparams.case,tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure};
		this.isListFinished = false;
		this.tableparams.page = 0;
		this.eventlist = [];
		this.getEventList(true);
	}

	markForClosureChange(){
		this.tableparams.mark_for_closure = !this.tableparams.mark_for_closure;
		this.excelDownloadParams={category:'',flag:this.tableparams.flag,isReplied:this.tableparams.isReplied,case:this.tableparams.case,tags:this.tableparams.tags,mark_for_closure:this.tableparams.mark_for_closure};
		this.tableparams.page = 0;
		this.isListFinished = false;
		this.eventlist = [];
		this.getEventList(true);
	}
	
	getEventList(isSearch){
		if(!this.isListFinished && !this.isLoading){
			let params = {
				category:this.tableparams.category,
				limit:this.tableparams.limit,
				page:this.tableparams.page,
				search:this.searchControl.value?this.searchControl.value:"",
				isReplied:this.tableparams.isReplied
			}
			for (let i = 0; i < this.tableparams.tags.length; i++) {
				let key = "tags["+i+"]";
				params[key] = this.tableparams.tags[i];
			}
			if(this.tableparams.flag!=null){
				params["flag"] = this.tableparams.flag;
			}
			if(this.tableparams.case){
				params["category"] = this.tableparams.case;
			}
			if(this.vesselId){
				params["vessel_id"] = this.vesselId;
			}
			if (this.tableparams.mark_for_closure) {
				params["mark_for_closure"] = this.tableparams.mark_for_closure;
			}
			this.isLoading = true;
			this.apiService.eventList(params).subscribe(data => {
				this.isLoading = false;
				let events :any = data["list"];
				if(events.length==this.tableparams.limit){
					this.tableparams.page += 1;
				}else{
					this.isListFinished = true;
				}
				if(isSearch){
					this.eventlist = [];
				}
				this.eventlist = this.eventlist.concat(events);
				for (var i = 0; i < this.eventlist.length; i++)
				{
					if(this.eventlist[i].symptoms !== null && typeof(this.eventlist[i].symptoms)==='string'){
						this.eventlist[i].symptoms = JSON.parse(this.eventlist[i].symptoms);
					}
				}
			},(error)=>{
				this.isLoading = false;
				this.isListFinished = true;
			});
		}
	}
	
	getExcel(){
		this.excelDownloadLoader = true;
    let data:any = this.excelDownloadParams;
    data.search = this.searchControl.value?this.searchControl.value:"";
		this.apiService.exportCases(data).subscribe(res => {
			let excelData:any=res["body"];
			this.excelDownloadLoader = false;
			let blob = new Blob([excelData], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
			var today = new Date();
			var day = today.getDate() + "";
			var month = (today.getMonth() + 1) + "";
			var year = today.getFullYear() + "";
			var hour = today.getHours() + "";
			var minutes = today.getMinutes() + "";
			var seconds = today.getSeconds() + "";
			
			day = this.checkZero(day);
			month = this.checkZero(month);
			hour = this.checkZero(hour);
			minutes = this.checkZero(minutes);
			seconds = this.checkZero(seconds);
			
			let fileName = day + "-" + month + "-" + year;
			FileSaver.saveAs(blob,"AllEventList_"+fileName+".xlsx");
		},(error)=>{
			this.excelDownloadLoader = false;
			this.toastr.error("Unable to download excel", 'Error',{timeOut:3000,tapToDismiss: true,closeButton: true});
		});
	}
	
	checkZero(data){
		if(data != undefined && data.toString().length == 1){
			data = "0" + data;
		}
		return data;
	}
	
	ngOnDestroy() {
		this.formCtrlSub.unsubscribe();
	}

	linkEvent(eventId:any){
		Swal.fire({
			title: '',
			icon:"info",
			html: 'Are you sure you want to link this event?',
			showCancelButton: true,
			confirmButtonColor: '#3a4aa6',
			confirmButtonText: 'Yes',
			cancelButtonColor: '#ea8b00',
			cancelButtonText: 'No',
		  }).then((result) => {
			if (result.value) {
				let param = {
					"case_status":"Open",
					"selectedVessel":this.vesselId,
					"eventId":eventId,
					"transactionId":this.transactionId.split("-"),
					"vessel_id":null
				}
				this.apiService.eventFromMailRequest(param).subscribe(
					data => {
						this.toastr.success("Event linked successfully.", 'Success!!!',{
							timeOut:3000,
							tapToDismiss: true,
							closeButton: true
						});
						setTimeout(() => {
							this.router.navigate(["/app/eventList"]);
						}, 1000);
					},
					(error)=>{
						this.toastr.error("Error while linking Event", 'Danger',{
							timeOut:3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
					);
			} else if (result.dismiss === Swal.DismissReason.cancel) {}
		  })
	}
}
