import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef, EventEmitter, Output, ChangeDetectionStrategy, IterableDiffers, DoCheck, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../api.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { FileQueueObject, FileuploadService } from '../fileupload.service';
import { timer } from 'rxjs';
import * as _ from 'lodash';
declare var $: any;

@Component({
	selector: 'app-create-event',
	templateUrl: './create-event.component.html',
	styleUrl: './create-event.component.css'
})
export class CreateEventComponent implements OnInit {
	breadcrumbList = [
	{
		path:"/app/eventList",
		name:"Home"
	}
	];
	showLoader=false;
	transactionId:any=null;
	eventForm!: FormGroup;
	vessels:any = [];
	events:any = [];
	constructor(
		private toastr: ToastrService,
		private apiService: ApiService,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private location:Location,
		public uploader: FileuploadService,
		private formBuilder: FormBuilder,
		private zone: NgZone,
		) {
	}

	ngOnInit(): void {
		this.activeRoute.queryParams.subscribe(queryParams => {});
		this.activeRoute.params.subscribe(routeParams => {
			this.transactionId = routeParams["transactionId"];
			let vesselId:any = routeParams["vesselId"];
			this.breadcrumbList.push({path:"/app/createEvent/"+this.transactionId,name:"Link Event"});
			this.eventForm = this.formBuilder.group({
				vessel: [null, [Validators.required]],
				event: [null, [Validators.required]],

			});
			this.getVessels();
			if(vesselId!="empty"){
				vesselId = parseInt(vesselId);
				this.f.vessel.setValue(vesselId);
				this.eventInVessel({vessel_id:vesselId});
			}
		});
	}

	get f() { return this.eventForm.controls; }

	getVessels(){
		this.apiService.fetchVessels().subscribe(
			data => {
				this.vessels = data;
			},
			(error)=>{
			}
			);
	}

	vesselSearchFn(term: string, item: any) {
		term = term.toLocaleLowerCase();
		return item.vessel.toLocaleLowerCase().indexOf(term) > -1;
	}

	eventInVessel(e){
		this.f.event.setValue(null);
		this.events = [];
		this.apiService.eventInVessel({vesselId:e.vessel_id}).subscribe(
			data => {
				this.events = data;
			},
			(error)=>{
				this.f.vessel.setValue(null);
				this.toastr.info("No events found for this vessel", 'Danger',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
			);
	}

	eventSearchFn(term: string, item: any) {
		term = term.toLocaleLowerCase();

		let isFirstNameMatched = false;
		if(item.firstName!=null){
			isFirstNameMatched = item.firstName.toLocaleLowerCase().indexOf(term) > -1;
		}
		let isLastNameMatched = false;
		if(item.lastName!=null){
			isLastNameMatched = item.lastName.toLocaleLowerCase().indexOf(term) > -1;
		}
		let isRankMatched = false;
		if(item.rank!=null){
			isRankMatched = item.rank.toLocaleLowerCase().indexOf(term) > -1;
		}
		let isEventIdMatched = false;
		if(item.eventId!=null){
			isEventIdMatched = item.eventId.toLocaleLowerCase().indexOf(term) > -1;
		}

		return isFirstNameMatched || isLastNameMatched || isRankMatched || isEventIdMatched;
	}

	saveEvent(){
		if (this.eventForm.invalid) {
			return;
		}
		let param = {
			"case_status":"Open",
			"selectedVessel":this.f.vessel.value,
			"eventId":this.f.event.value,
			"transactionId":this.transactionId.split("-"),
			"vessel_id":null
		}
		this.showLoader = true;
		this.apiService.eventFromMailRequest(param).subscribe(
			data => {
				this.showLoader = false;
				this.toastr.success("Event linked successfully.", 'Success!!!',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
				setTimeout(() => {
					this.router.navigate(["/app/eventList"]);
				}, 1000);
			},
			(error)=>{
				this.showLoader = false;
				this.f.vessel.setValue(null);
				this.toastr.error("Error while linking Event", 'Danger',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
			);
	}

}
