
<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
<section class="content" >
	<ol class="breadcrumb">
		<li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index" [class.active]="i===breadcrumbList.length-1">
			<a [routerLink]="item.path" id="breadcrumbLink{{i}}" *ngIf="i!==breadcrumbList.length-1">
				<b>{{ item.name }}</b>
			</a>
			<span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
		</li>
	</ol>
	<div class="form-group row">
		<div class="col-sm-12">
			<div class="panel panel-default">
				<div class="panel-body">
					<div class="app-view-header headerstyle">Link Event</div>
					<button id="btnBack" [routerLink]="['/app/mailbox/folder/inbox']" class="pull-right mr btn-xs mb-sm btn btn-info">Back</button>
					<hr/>
					<div>
						<form class="form-horizontal" [formGroup]="eventForm" (ngSubmit)="saveEvent()" #eventform="ngForm">
							<div class="panel panel-info">
								<div class="panel-heading">
									Link With Existing Event
								</div>
								<div class="panel-body form-group mb0">
									<div class="col-lg-3 col-sm-3">
										<label><span style="color: #b40a09;">*</span>Vessel</label>
										<ng-select [items]="vessels" bindLabel="vessel" bindValue="vessel_id" [searchFn]="vesselSearchFn" [clearable]="false" placeholder="Select Vessel" id="vessel" name="vessel" formControlName="vessel" (change)="eventInVessel($event)">
											<ng-template ng-label-tmp let-item="item">
												{{item.vessel}}
											</ng-template>
											<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
												{{item.vessel}}
											</ng-template>
										</ng-select>
										<small *ngIf="f.vessel.errors && (eventform.submitted || f.vessel.touched || f.vessel.dirty)" style="color: maroon;">
											<span *ngIf="f.vessel.errors.required">
												Please select vessel.
											</span>
										</small>
									</div>
									<div class="col-lg-3 col-sm-3">
										<label><span style="color: #b40a09;">*</span>Link to Existing Event</label>
										<ng-select [items]="events" bindLabel="eventId" bindValue="id" [searchFn]="eventSearchFn" [clearable]="false" placeholder="Select Field" id="event" name="event" formControlName="event" [readonly]="!f.vessel.value">
											<ng-template ng-label-tmp let-item="item">
												{{item.eventId}}
											</ng-template>
											<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
												{{item.cname}}<br>
												Rank-{{item.rank}} ({{item.eventId}})
											</ng-template>
										</ng-select>
									</div>
								</div>
							</div>
							<div class="panel-body text-center">
								<button id="btnSubmit" type="submit" ripple="" class="mr mb-sm btn btn-sm btn-success" [disabled]="eventform.invalid"><span><i *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin"></i><i *ngIf="!showLoader" class="fa fa-save"></i></span>&nbsp;Save</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>