import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {
	
	constructor(private router: Router,private apiService: ApiService) { }

	getRandomUniqueString(){
		/* Create a new Date object. */
		const date = new Date();
		/* Get the current timestamp in milliseconds. */
		const timestamp = date.getTime();
		/* Convert the timestamp to a string. */
		const timestampString = timestamp.toString();
		/* Generate a random number between 0 and 1000. */
		const randomNumber = Math.floor(Math.random() * 1000);
		/* Combine the timestamp string and the random number to create a unique string. */
		const uniqueString = timestampString + randomNumber;
		return uniqueString;
	}
	
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const httpRequest = req.clone({
			/* setParams:{"random":this.getRandomUniqueString()} */
		})
		return next.handle(httpRequest).pipe( tap(() => {},(err: any) => {
			if (err instanceof HttpErrorResponse) {
				if (err.status !== 401) {
					return;
				}
				localStorage.clear();
				localStorage.setItem("sessionexpire","true");
				this.apiService.sendSessionExpMessage();
				this.router.navigate(['/login']);
			}
		}));
	}
}
