
<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
<link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
<section class="content" >
	<ol class="breadcrumb">
		<li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index" [class.active]="i===breadcrumbList.length-1">
			<a [routerLink]="item.path" id="breadcrumbLink{{i}}" *ngIf="i!==breadcrumbList.length-1">
				<b>{{ item.name }}</b>
			</a>
			<span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
		</li>
	</ol>
	<div *ngIf="moduleAccess=='Full' || moduleAccess=='Readonly'" class="form-group row">
		<div class="col-sm-12">
			<div class="panel panel-default">
				<div class="panel-body">
					<div class="app-view-header headerstyle">
						<ng-container *ngIf="!vesselData">All Cases</ng-container>
						<ng-container *ngIf="vesselData">
							<span>Vessel: {{vesselData?.vesselName}}</span><br />
							<span style="font-size: 16px;">Fleet: {{vesselData?.companyName}}</span>
						</ng-container>
						<a id="genEventId" *ngIf="transactionId" [routerLink]="['/app/generateVesselEvent',transactionId,'empty',vesselId]" class="btn-sm mr mb-sm btn btn-primary pull-right">Generate Event</a>
					</div>
					<div>
						<div style="padding-top: 10px;width: 100%;" class="pull-left">
							<input id="searchBox" placeholder="Search By CaseID, Candidate, Vessel, Fleet, Status " style="font-size: 16px;margin-left: 5px;width: 350px;float: left;" [formControl]="searchControl" class="form-control"/>
							<ng-select style="margin-left: 5px;width: 450px;float: left;" [items]="tagMaster" appendTo="body" [clearable]="true" [multiple]="true"
							placeholder="Search By Tags" id="tags" name="tags" [(ngModel)]="tableparams.tags" (change)="searchByTags()">
							<ng-template ng-label-tmp let-item="item">
								{{item}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
								{{item}}
							</ng-template>
							</ng-select>
							<!-- <label style="margin-top: 5px;margin-left: 10px;cursor: pointer;float: left;">
								<input type="checkbox" id="checkMarkForClosure" [(ngModel)]="tableparams.mark_for_closure" [ngModelOptions]="{standalone: true}" (change)="markForClosureChange()"/>
								Marked for closure
							</label> -->
							<!-- <i *ngIf="eventlist.length" style="cursor: pointer" (click)="getExcel()" title="Excel Download" class="fa fa-download ml fa-2x text-success pull-left"></i><i *ngIf="excelDownloadLoader" class="fa fa-spin fa-spinner ml fa-2x pull-left"></i> -->
							<div class="radio-inline c-radio pull-left" style="margin-left: 40px;">
								<label style="width: 50px;">
									<input type="radio" name="statusFilter" value="" [(ngModel)]="tableparams.case"  (change)="searchByStatus()" />
									<span class="fa fa-circle"></span>All
								</label>
								<label style="width: 70px;">
									<input type="radio" name="statusFilter" value="Open" [(ngModel)]="tableparams.case"  (change)="searchByStatus()" />
									<span class="fa fa-circle"></span>Open
								</label>
								<label style="width: 110px;">
									<input type="radio" name="statusFilter" value="Repatriation" [(ngModel)]="tableparams.case"  (change)="searchByStatus()" />
									<span class="fa fa-circle"></span>Repatriation
								</label>
								<label style="width: 50px;">
									<input type="radio" name="statusFilter" value="Closed" [(ngModel)]="tableparams.case"  (change)="searchByStatus()" />
									<span class="fa fa-circle"></span>Closed
								</label>
							</div>
							
							<div ngbDropdown container="body" style="display: inline-block;" class="pull-right">
								<button type="button" class="btn btn-sm btn-primary mr-sm" id="filterDropDown" ngbDropdownToggle>
									<i class="fa fa-filter" aria-hidden="true"></i>
									Options
									<i class="fa fa-angle-down" aria-hidden="true"></i>
								</button>
								<div ngbDropdownMenu aria-labelledby="filterDropDown" style="width: fit-content;">
									<button ngbDropdownItem id="filterBtn0" (click)="toggleNotReplied()">
										<span [ngStyle]="{'cursor':tableparams.isReplied==1 ?'default':'pointer'}" [ngClass]="{'text-bold text-black text-uppercase':tableparams.isReplied==1}" style="padding-right: 20px;" id="toggleNotRepliedBtn"><i [ngStyle]="{'cursor':tableparams.isReplied==1 ?'default':'pointer'}" aria-hidden="true" style="color:#3f51b5" class="fa fa-envelope mr"></i>
											<span [ngStyle]="{'border-bottom':tableparams.isReplied==1 ?'2px solid #0a0a0a':''}">Not Replied</span>
										</span>
									</button>
									<button ngbDropdownItem id="filterBtn1" (click)="markForClosureChange()">
										<span [ngStyle]="{'cursor':tableparams.mark_for_closure ?'default':'pointer'}" [ngClass]="{'text-bold text-black text-uppercase':tableparams.mark_for_closure}" style="padding-right: 20px;" id="toggleNotRepliedBtn"><i [ngStyle]="{'cursor':tableparams.mark_for_closure ?'default':'pointer'}" aria-hidden="true" style="color:#3f51b5" class="fa fa-window-close mr"></i>
											<span [ngStyle]="{'border-bottom':tableparams.mark_for_closure ?'2px solid #0a0a0a':''}">Marked for closure</span>
										</span>
									</button>
									<button ngbDropdownItem id="filterBtn2" (click)="flagAllEvents(5)">
										<span [ngStyle]="{'cursor':tableparams.flag==5 ?'default':'pointer'}" [ngClass]="{'text-bold text-primary text-uppercase':tableparams.flag==5}" style="padding-right: 20px;" id="flagAllEventsBtn5"><i aria-hidden="true" class="fa fa-square mr text-primary"></i>
											<span [ngStyle]="{'border-bottom':tableparams.flag==5 ?'2px solid #3f51b5':''}">CODE BLUE</span>
										</span>
									</button>
									<button ngbDropdownItem id="filterBtn3" (click)="flagAllEvents(1)">
										<span [ngStyle]="{'cursor':tableparams.flag==1 ?'default':'pointer'}" [ngClass]="{'text-bold text-black text-uppercase':tableparams.flag==1}" style="padding-right: 20px;" id="flagAllEventsBtn1"><i [ngStyle]="{'cursor':tableparams.flag==1 ?'default':'pointer'}" aria-hidden="true" style="color:#0a0a0a" class="fa fa-flag mr"></i>
											<span [ngStyle]="{'border-bottom':tableparams.flag==1 ?'2px solid #0a0a0a':''}">Unassigned</span>
										</span>
									</button>
									<button ngbDropdownItem id="filterBtn4" (click)="flagAllEvents(4)">
										<span [ngStyle]="{'cursor':tableparams.flag==4 ?'default':'pointer'}" [ngClass]="{'text-bold text-danger text-uppercase':tableparams.flag==4}" style="padding-right: 20px;" id="flagAllEventsBtn4"><i aria-hidden="true" class="fa fa-flag mr text-danger"></i>
											<span [ngStyle]="{'border-bottom':tableparams.flag==4 ?'2px solid #f34235':''}">Emergency</span>
										</span>
									</button>
									<button ngbDropdownItem id="filterBtn5" (click)="flagAllEvents(2)">
										<span [ngStyle]="{'cursor':tableparams.flag==2 ?'default':'pointer'}" [ngClass]="{'text-bold text-warning text-uppercase':tableparams.flag==2}" style="padding-right: 20px;" id="flagAllEventsBtn2"><i aria-hidden="true" class="fa fa-flag mr text-warning"></i>
											<span [ngStyle]="{'border-bottom':tableparams.flag==2 ?'2px solid #fe9700':''}">Routine</span>
										</span>
									</button>
									<button ngbDropdownItem id="filterBtn6" (click)="flagAllEvents(3)">
										<span [ngStyle]="{'cursor':tableparams.flag==3 ?'default':'pointer'}" [ngClass]="{'text-bold text-success text-uppercase':tableparams.flag==3}" style="padding-right: 20px;" id="flagAllEventsBtn3"><i aria-hidden="true" class="fa fa-flag mr text-success"></i>
											<span [ngStyle]="{'border-bottom':tableparams.flag==3 ?'2px solid #4caf50':''}">Improving</span>
										</span>
									</button>
									<button *ngIf="tableparams.flag" ngbDropdownItem id="filterBtn7" (click)="flagAllEvents(null)">
										<span *ngIf="tableparams.flag" style="padding-right: 20px;cursor: pointer;" id="flagAllEventsBtnNull"><i aria-hidden="true" class="fa fa-refresh mr text-info"></i> &nbsp;Show All</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row mt-lg">
						<div class="col-sm-12">
							<cdk-virtual-scroll-viewport itemSize="50" (scrolledIndexChange)="$event+7>=eventlist.length?getEventList(false):null" class="infinite-viewport">
								<table class="table table-striped table-bordered table-hover" >
									<thead>
										<tr>
											<th class="tableth_heading text-center">Case ID</th>
											<th class="tableth_heading text-center">Date Of Case</th>
											<th class="tableth_heading text-center">Candidate Name</th>
											<th *ngIf="!vesselData" class="tableth_heading text-center">Vessel Name</th>
											<th *ngIf="!vesselData" class="tableth_heading text-center">Fleet</th>
											<th class="tableth_heading text-center">Status</th>
											<th class="tableth_heading text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *cdkVirtualFor="let eve of eventlist" class="table_td_style">
											<td class="text-center">{{eve.eventId}}
												<span *ngIf="eve.emailStatus=='Unread'" title="New mail" class="mr-sm"><i aria-hidden="true" class="fa fa-envelope"></i>
												</span>
												<span *ngIf="eve.isReplied==1" title="Not Replied"><i aria-hidden="true" style="color: #3f51b5;" class="fa fa-envelope"></i>
												</span>
											</td>
											<td class="text-center">{{fixDate(eve.createdAt)}}</td>
											<td class="text-center text-uppercase">{{eve.candname}}</td>
											<td *ngIf="!vesselData" class="text-center text-uppercase">{{eve.vesselName}}</td>
											<td *ngIf="!vesselData" class="text-center text-uppercase">{{eve.com_name}}</td>
											<td class="text-center">{{eve.caseStatus}}</td>
											<td class="text-center">
												<i class="fa fa-info-circle mr" [title]="'Symptoms : ' + eve.symptoms"></i>
												<button title="View Case Details" [ngStyle]="set_color(eve)" [routerLink]="['/app/eventDetails',eve.id]" class="btn btn-xs text-white text-bold" id="btnViewDetails{{eve.id}}">View</button>
												<button *ngIf="transactionId" type="button" class="btn btn-xs btn-primary text-white text-bold ml-sm" title="Link" (click)="linkEvent(eve.id)">
                                                    <i class="fa fa-link"></i>
                                                </button>
											</td>
										</tr>
									</tbody>
								</table>
								<div *ngIf="!eventlist.length &amp;&amp; !isLoading" class="text-center text-bold">
									Data Not Found.
								</div>
								<div *ngIf="isLoading" class="text-center text-bold">
									<i aria-hidden="true" class="fa fa-spinner fa-spin"></i> Loading more events.
								</div>
								<div *ngIf="!isLoading && !isListFinished" class="text-center text-bold">
									<button id="btnLoadMore" title="Load More" (click)="getEventList(false)" class="btn btn-primary">Load More</button>
								</div>
							</cdk-virtual-scroll-viewport>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="moduleAccess!='Full' && moduleAccess!='Readonly'">
		<div class="text-center">
			<h4>You don&apos;t have access to this section.</h4>
		</div>
	</div>
</section>
